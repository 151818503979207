/*!
 * Datepicker for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */
/*
 *
 *   SCSS by Creative Tim
 *   http://www.creative-tim.com
 *
 */

.datepicker {
  top: 0;
  left: 0;
  padding: 4px;
  margin-bottom: 0;
  background-color: $white-color;

  &.form-control {
    padding: 17px 40px;
  }
}
.datepicker > div {
  display: none;
}
.datepicker table {
  width: 100%;
  margin: 0;
  box-shadow: none;
}
.datepicker td,
.datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
}
.datepicker td {
  text-align: center;
}
.datepicker td p {
  font-size: 14px;
  border-radius: 50%;
  height: 35px;
  line-height: 31px;
  margin: 5px 0;
  width: 35px;
  transition: all .3s ease;
  border: 2px solid transparent;
}
.datepicker td :hover {
  cursor: pointer;
}
.datepicker th {
  font-weight: 500;
}
.datepicker th.switch-datepicker {
  font-size: 1em;
}
.datepicker .prev p,
.datepicker .next p {
  font-size: 1.825em;
}
.datepicker .day.disabled {
  color: #eeeeee;
}

.datepicker table > tbody > tr > td.day {
  color: $icon-color;
}

.datepicker table > tbody > tr > td.old,
.datepicker table > tbody > tr > td.new {
  color: #e9edf6;
  border-top: 0;
}
.datepicker td.active p {
  border: 3px solid $primary-color;
  position: relative;
  color: $primary-color;
}

.datepicker td.active:hover p {
  background-color: transparent;
}

.datepicker td:hover p {
  background-color: #e9edf6;
  color: $border-color;
}
.datepicker td.primary p,
.datepicker td.primary:hover p {
  background-color: #9c27b0;
}
.datepicker td.info p,
.datepicker td.info:hover p {
  background-color: #03a9f4;
}
.datepicker td.success p,
.datepicker td.success:hover p {
  background-color: #4caf50;
}
.datepicker td.warning p,
.datepicker td.warning:hover p {
  background-color: #fbc02d;
}
.datepicker td.danger p,
.datepicker td.danger:hover p {
  background-color: #f44336;
}
.datepicker span {
  display: block;
  width: 55px;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 2px;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.datepicker span.old {
  color: #999999;
}
.datepicker span.active, .datepicker span.active:focus, .datepicker span.active:hover, .datepicker span.active:active {
  background-color: #9c27b0;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
}
.datepicker span.active {
  color: #FFFFFF;
}
.datepicker span:hover {
  background-color: #EEEEEE;
}
.datepicker span.primary,
.datepicker span.primary:hover {
  background-color: #9c27b0;
}
.datepicker span.info,
.datepicker span.info:hover {
  background-color: #03a9f4;
}
.datepicker span.success,
.datepicker span.success:hover {
  background-color: #4caf50;
}
.datepicker span.warning,
.datepicker span.warning:hover {
  background-color: #fbc02d;
}
.datepicker span.danger,
.datepicker span.danger:hover {
  background-color: #f44336;
}
.datepicker th.switch-datepicker {
  width: 145px;
}
.datepicker th.next,
.datepicker th.prev {
  font-size: 21px;
}
.datepicker thead tr:first-child th {
  cursor: pointer;
}
.datepicker.dropdown-menu {
  position: absolute;
  background-color: $white-color;
  z-index: 20;
  border-radius: 30px;
  box-shadow: 15px 0 40px 5px rgba(61,67,79,.08);
  transition: top, left, opacity, visibility 150ms linear;
  margin-top: 10px;
  opacity: 0;
  visibility: hidden;
  padding: 20px 34px;
}
.datepicker.dropdown-menu.open {
  opacity: 1;
  visibility: visible;
}
.datepicker .table-condensed > tbody > tr > td {
  padding: 0;
}
.datepicker .table-condensed > thead > tr > th {
  padding: 0;
  height: 100%;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
  display: block;
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.datepicker-months thead {
  padding: 0 0 3px;
  display: block;
}


/*------------- #DatePicker Customizations --------------*/

.has-datepicker {
  position: relative;
  z-index: 19;

  &.is-focused, &:active {

    .ericore-icon {
      color: $primary-color;
      fill: $primary-color;
    }
  }
}

.datepicker-icons {
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translate(0, -50%);
  height: 24px;
  width: 24px;

  .ericore-icon {
	font-size: 24px;
	height: 24px;
	width: 24px;
	color: $link-color;
	fill: $link-color;
    position: relative;
    z-index: 5;
  }
}

.datepicker {
  color: $input-color;

  p {
    margin-bottom: 0;
  }

  table > thead > tr > th {
    height: 60px;
  }

  table > tbody > tr {

	&:hover {
	  box-shadow: none;

	  td {
		background-color: transparent;
		border-color: transparent;
	  }
	}
  }

  table,
  table > thead > tr > th,
  table > tbody > tr > td {
    border-color: transparent;
	color: $border-color;
	background-color: transparent;
  }

  tbody > tr > td.day {
    font-size: 14px;
    font-weight: $weight-black;
  }

  table > thead > tr > th.dow {
    font-size: 12px;
  }

  table > thead > tr > th.dow:last-child {
	color: $red-light;
  }

  table > thead > tr:last-child > th {
    height: 70px;
  }

  table > tbody > tr > td.active {
    background-color: transparent;
  }

  table > tbody {
	margin-top: 5px;
  }

  .next p,
  .prev p {
	font-size: 1em;
	color: $link-color;
	fill: $link-color;
	position: relative;
    transition: all .3s ease;
    height: 100%;
    line-height: 2;

	.ericore-icon {
	  position: relative;
	  width: 13px;
	  height: 13px;
	  z-index: 2;
	}

    &:hover {
      color: $primary-color;
	  fill: $primary-color;

      &:after {
        opacity: 1;
      }
    }
  }

  th.next,
  th.prev {
	font-size: 1.5em;
  }

  th {
	font-weight: $weight-black;
	vertical-align: middle;

    &.switch-datepicker {
      letter-spacing: .2em;
    }
  }
}


/*------------- #DatePicker Dark --------------*/

.datepicker--dark {
  .datepicker {
	background-color: $bg-accordion-grey;
	color: $icon-color;

	&:focus {
	  color: $link-color;

	  & + .datepicker-icons .ericore-icon {
		color: $primary-color;
		fill: $primary-color;
	  }
	}
  }

  .datepicker-icons .ericore-icon {
	color: $icon-color;
	fill: $icon-color;
  }
}

.datepicker.datepicker--dark-dropdown {
  background-color: $bg-accordion-grey;

  table > thead > tr > th.switch-datepicker {
	color: $white-color;
  }

  table > tbody > tr > td.day {
	color: $link-color;
  }

  table > tbody > tr > td.old {
	color: $border-color;
  }

  table > thead > tr > th {
	color: $icon-color;
  }

  .next p,
  .prev p {
	color: $border-color;
	fill: $border-color;

	&:hover {
	  color: $primary-color;
	  fill: $primary-color;
	}
  }

  td:hover p {
	background-color: $border-color;
	color: $white-color;
  }

  td.active:hover p {
	background-color: transparent;
  }

}

/*------------- #DatePicker Squared --------------*/

.datepicker--squared {

  .datepicker {
    border-radius: 7px;
  }

  &.is-focused, &:active {

    .datepicker {
      border-radius: 7px 7px 0 0;
    }

	.ericore-icon {
	  color: $primary-color;
	  fill: $primary-color;
	}
  }

  &.datepicker--dark {

	&.is-focused,
	&:active {

	  .datepicker {
		border: 3px solid $bg-accordion-faqs;
		background-color: $bg-accordion-faqs;
	  }
	}

	.datepicker {
	  background-color: transparent;
	  border: 3px solid $dark-lighter;
	}

	.datepicker-icons .ericore-icon {
	  color: $border-color;
	  fill: $border-color;
	}
  }
}

.datepicker.datepicker--squared-dropdown {
  border-radius: 0 0 7px 7px;
  margin-top: 0;

  table > tbody > tr > td.day {
	border-bottom: 1px solid #e9edf6;

	&.old, &.new {
	  border-bottom-color: transparent;
	}
  }

  table {
	border-radius: 0;
  }

  table > thead > tr:first-child {
    background-color: $secondary-color;
  }

  td {

	p {
	  border: 3px solid transparent;
	  position: relative;
	}

	&.active {
	  p {
		border-color: $primary-color;
	  }

	  &:hover {
		p {
		  border-color: $primary-color;
		  background-color: transparent;
		}
	  }
	}

    &:hover {

	  p {
		background-color: #e9edf6;
		border-color: #e9edf6;
	  }
    }
  }
}


.datepicker.datepicker--squared-dark-dropdown {
  border-radius: 0 0 7px 7px;
  margin-top: 0;
  background-color: $bg-accordion-faqs;

  table > tbody > tr > td.day {
	border-bottom: 1px solid $hr-color;

	&.old, &.new {
	  border-bottom-color: transparent;
	}
  }

  .next p,
  .prev p {
	color: $border-color;
	fill: $border-color;

	&:hover {
	  color: $primary-color;
	  fill: $primary-color;
	}
  }

  table > thead > tr > th.switch-datepicker {
	color: $white-color;
  }

  td:hover p {
	background-color: $border-color;
	color: $white-color;
  }

  table > tbody > tr > td.day {
	color: $link-color;
  }

  table > thead > tr > th.dow {
	color: $icon-color;
  }

  td {

	&.active {

	  &:hover {
		p {
		  border-color: $primary-color;
		  background-color: transparent;
		}
	  }
	}
  }
}


/*================= Responsive Mode ============*/


@media (max-width: 480px) {
  .datepicker td p {
	width: 30px;
	height: 30px;
	font-size: 12px;
	line-height: 26px;
  }

  .datepicker.dropdown-menu {
	padding: 20px 30px;
  }
}